@import '_variables';
@import '_mixins';

.perfect-fit-section {
  width: 100%;
  min-height: 740px;
  height: 940px;
  margin-top: 3vw;
  display: flex;
  overflow: hidden;
  .card {
    display: flex;
    justify-content: space-around;
    width: 100%;
    @include mobile {
      display: block;
      width: auto;
    }
  }

  @include mobile {
    #item-3 {
      .question {
        .question-line {
          margin-left: 4px;
        }
        // height: 440px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
  .final {
    #item-3 {
      .question {
        height: 100%;
      }
    }
  }
  .clothes-container {
    margin-top: 50px;
    @include mobile {
      margin-top: 5px;
    }
    transition: all 0.4s;
  }
  @include mobile-l {
    flex-direction: column;
  }
  @include mobile {
    flex-direction: column;
    height: auto;
    min-height: auto;
  }
  .body-content {
    position: relative;
    display: flex;
    width: 100%;
    .question-content {
      height: 100%;
    }
    @include mobile {
      .slide {
        align-items: flex-start;
        padding-top: 50px;
      }
      min-height: 900px;
      height: 890px;
      width: 100%;
    }

    @include mobile-l {
      min-height: 100vh;
      width: 100%;
    }

    .question {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include mobile {
        .question-line {
          flex-direction: column;
          align-items: center;
          .fix-wrap {
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
        justify-content: center;
        align-items: center;
        .question-cont {
          align-items: center;
          width: 100%;
        }
      }
      .select {
        width: 600px;
        margin-top: 30px;
        @include mobile {
          width: 100%;
        }
      }
      input {
        margin: 0 5px;
        width: 200px;
      }
      input:focus ~ .border:before {
        width: 220px;
      }
      .border {
        width: 220px;
      }
      .question-cont {
        display: flex;
        align-items: center;
        padding-bottom: 1vw;
        flex-direction: column;
        justify-content: center;
      }
      @include mobile-l {
        justify-content: flex-start;
        padding-left: 10px;
        padding-right: 10px;
        .question-cont {
          padding-bottom: 4vw;
          padding-top: 2vw;
          .question-line {
            flex-wrap: wrap;
          }
        }

        input {
          margin: 0 5px;
          width: 140px;
        }
        input:focus ~ .border:before {
          width: 160px;
        }
        .border {
          width: 160px;
        }
      }
      @include mobile {
        justify-content: flex-start;
        padding-left: 10px;
        padding-right: 10px;
        .question-cont {
          padding-bottom: 2vw;
          padding-top: 2vw;
        }
      }
    }
    .question {
      .question-line {
        display: flex;
        margin-top: 16px;
        span:last-child {
          align-self: flex-end;
        }
      }
    }
  }
  .final {
    .card {
      padding-bottom: 15px;
      justify-content: space-around;
      width: 100%;
      display: flex;
      @include mobile {
        .select-main:first-child {
          margin-top: 0;
        }
        display: block;
        width: auto;
      }
    }
    .item {
      padding-bottom: 0;
    }
    margin-top: 15px;
    input {
      color: $COLOR_TEXT_LIGHT;
    }
    .focused-select {
      display: block;
    }
    .next-button {
      margin-top: 51px;
    }
    @include mobile {
      min-height: auto;
      .next-button {
        margin-top: 30px;
      }
      .item {
        padding-bottom: 0;
        .item-fix {
          // border: 1px solid red;
          width: 95% !important;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    .question {
      @include mobile {
        .question-cont {
          .question-line {
            margin-top: 0;
            margin-bottom: 15px;
          }
          align-items: flex-start;
        }
        .question-line {
          flex-direction: row;
          align-items: center;
          justify-content: center;
          .back-text {
            text-align: center;
          }
          .fix-wrap {
            flex-direction: row;
            justify-content: center;
            align-items: center;
          }
        }
        justify-content: flex-start;
        align-items: flex-start;
        .question-cont {
          align-items: center;
          width: 100%;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}

.scale-btn {
    padding: 20px;
    max-height: 69px;
    min-width: 250px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #DBD0DA;
    margin-top: 29px;
    cursor: pointer;
    outline: none;
    border: none;

  &.selected-scale-btn{
    background-color: #B6A1B5;
  }

    .scale-btn-text {
        color: #FFFFFF;
        font-family: Value;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
    }
}
