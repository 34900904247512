@import '_mixins';
@import '_variables';

.main-container {
  width: 75vw;
  @include mobile-l {
    width: 100%;
  }
  @include FourK {
    width: 55vw;
  }
  @include desktop-w {
    width: 90vw;
  }
  @include desktop {
    width: 90vw;
  }
  @include tablet {
    width: 95vw;
    margin-top: 0;
  }
  @include mobile {
    width: 100vw;
    margin-top: 0;
  }
  @include mobile-l {
    width: 100vw;
    margin-top: 0;
    .main-header {
      margin-bottom: 10px;
    }
  }
}

.main-container {
  margin-top: 50px;
  @include mobile {
    margin-top: 0;
  }
  .main-header {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vw;
    @include FourK {
      margin-bottom: 1vw;
    }

    font-family: Futura;
    .empty {
      width: 60px;
      height: 60px;
    }
    .button-back {
      width: 60px;
      height: 60px;
      background-color: $COLOR_PINK;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: 0;
      transition: all 0.2s;
      cursor: pointer;
      path {
        fill: $COLOR_WHITE;
      }
    }
    @include mobile {
      height: 40px;
      .button-back {
        width: 40px;
        height: 40px;
      }
    }
    .button-back:active {
      background-color: transparent;
      path {
        fill: $COLOR_PINK;
      }
    }
    .button-back:hover {
      svg {
        transition: all 0.3s;
        transform: translateX(-4px) scaleX(1.1) scaleY(1.1);
      }
    }
    .header-content {
      margin-right: 60px;
      @include mobile {
        margin-right: 40px;
      }
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      h4 {
        color: $COLOR_TEXT_LIGHT !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 110%;
        text-align: center;
        letter-spacing: 3px;
        text-transform: uppercase;
      }
    }
  }
  .title-cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main-title {
    font-style: normal;
    font-weight: 500;
    letter-spacing: 3px;
    font-size: 36px;
    line-height: 126%;
    text-align: center;
    margin: 1vw 0 !important;
    width: 70%;
    @include desktop-w {
      width: 80%;
    }
    @include desktop {
      width: 90%;
    }
    @include tablet {
      width: 80%;
    }
    @include mobile {
      margin-top: 5px;
      width: 95%;
      font-size: 26px;
      line-height: 30px;
    }
  }
  .main-window {
    background-color: $COLOR_WHITE;
    width: 100%;
  }
}

.transparent {
  background-color: transparent;
}
