@import 'normalize';
@import '_variables';
@import '_mixins';

@font-face {
  font-family: 'Value';
  src: url('../assets/fonts/Serif6Beta-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Futura';
  src: url('../assets/fonts/FuturaPTBook.otf') format('opentype');
}

@import 'main-template';
@import 'clothes-section';
@import 'check-box';
@import 'are-you-in-section';
@import 'next-button';
@import 'body-section';
@import 'question-template';
@import 'previus-inline-button';
@import 'input';
@import 'animated-select';
@import 'select';
@import 'thank-for-describing';
@import 'search-input';
@import 'favorit-brands';
@import 'brands-sizes';
@import 'bordered-select';
@import 'different-size';
@import 'perfect-fit';
@import 'everyday-section';
@import 'brands-card';
@import 'scroller';

.App {
  font-family: Value;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // height: 2000px;
  .layout {
    height: 500px;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $COLOR_LAYOUT_BACKGROUND;
    position: absolute;
    z-index: -99999;
    @include desktop {
      height: 50vh;
    }
    @include mobile {
      height: 250px;
    }
  }
}
.slider {
  width: 100%;
  height: 100%;
  overflow: hidden;
  @include mobile {
    overflow: auto;
  }
  .slide {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.black-title {
  font-style: normal;
  font-weight: 500 !important;
  letter-spacing: 3px;
  font-size: 48px !important;
  line-height: 110% !important;
  text-align: center;
  color: $COLOR_TEXT_BLACK !important;
  margin: 0;
}
.pink-title {
  font-style: normal;
  font-weight: 500;
  letter-spacing: 3px;
  font-size: 48px;
  line-height: 110%;
  text-align: center;
  color: $COLOR_PINK !important;
  margin: 0;
}
.grey-text {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 136%;
  text-align: center;
  color: $COLOR_TEXT_LIGHT;
  margin: 0;
}
.back-text {
  font-style: normal;
  font-weight: 500;
  letter-spacing: 3px;
  font-size: 18px;
  line-height: 32px;
  color: $COLOR_BLACK;
}
.mini-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 3px;
  line-height: 136%;
  text-align: center;
  color: $COLOR_TEXT_BLACK;
}
.grey-text-mini {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: $COLOR_TEXT_LIGHT;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background: #acabae66;
  border-radius: 10px;
  border: 1px solid #ffffff;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 26px;
  min-height: 20px;
}
