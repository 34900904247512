@import '_variables';
@import '_mixins';

.clothes-container {
  display: flex;
  flex: 1 1 auto;
  margin-top: 5vw;
  @include FourK {
    margin-top: 1vw;
  }
  justify-content: center;
  align-items: flex-start;
  background-color: transparent;
  .clothes-left,
  .clothes-right {
    display: flex;
    flex: 1 1 auto;
    background-color: transparent;
  }
  .clothes-left {
    justify-content: flex-end;
  }
  .clothes-right {
    justify-content: flex-start;
  }
  @include mobile {
    flex-direction: column;
    align-items: center;
    // border: 1px solid red;
    .clothes-left,
    .clothes-right {
      justify-content: space-evenly;
      width: 100%;
    }
  }
  @include mobile-l {
    flex-direction: column;
    align-items: center;
    .clothes-left,
    .clothes-right {
      justify-content: space-between;
      // flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }
}

.clothes-card {
  width: 13vw;
  margin: 0 15px;
  background-color: $COLOR_WHITE;
  .clothes-text {
    font-style: normal;
    font-weight: bold;
    letter-spacing: 3px;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: $COLOR_TEXT_BLACK;
  }
  @include desktop {
    width: 15vw;
  }
  @include tablet {
    width: 19vw;
  }
  @include mobile {
    width: 40vw;
    margin: 5px 15px;
  }
  @include mobile-l {
    // width: 80vw;
    .clothes-text {
      font-size: 14px;
      line-height: 18px;
      margin: 8px 0;
    }
  }
}
