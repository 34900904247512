@import '_variables';

.prev-button {
  span {
    font-family: Futura !important;
    line-height: 120%;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 3px;
    font-size: 18px !important;
    color: $COLOR_INLINE_PINK !important;
  }
  display: flex;
  align-items: center;
  width: auto;
  user-select: none;
  outline: 0;
  transition: all 0.5s;
  cursor: pointer;
  svg {
    path {
      fill: $COLOR_INLINE_PINK;
    }
  }
  span {
    margin-left: 15px;
  }
}
.prev-button:hover {
  font-style: italic;
  svg {
    transition: all 0.3s;
    transform: scaleX(1.2) translateX(-4px);
  }
}

.toRight {
  span {
    margin-left: 0;
    margin-right: 15px;
  }
  svg {
    transition: all 0.3s;
    transform: rotateY(180deg);
  }
}
.toRight:hover {
  font-style: italic;
  svg {
    transition: all 0.3s;
    transform: rotateY(180deg) scaleX(1.2) translateX(-4px);
  }
}
